import * as React from 'react'
import { Button, Menu } from '@mui/material';

import { useRouter } from 'next/router';
import { ClickAwayListener } from '@mui/material';

type Props = {
    children: any,
    title: any,
    path: string,
    useUnderline?: boolean
}

const DropDownMenuItem: React.FC<Props> = ({ children, title, path, useUnderline=true }: Props) => {
    const router = useRouter();
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleOpen(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }


    let currentPathMatches = router.pathname.includes(path);
    const matchClass = currentPathMatches && useUnderline ? 'underline' : '';

    return (
        <div className="px-1" style={{ paddingTop: '2px' }}                 
        onMouseLeave={handleClose}>
            <Button
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleOpen}
                onMouseEnter={handleOpen}
                style={{ zIndex: 1301 }}
                color="secondary"
            >
                <div className={'text-sm normal-case font-medium text-gray-700' + matchClass} 
                // style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                >
                    {title}
                </div>

            </Button>

            <Menu
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                MenuListProps={{ onMouseLeave: handleClose, style: {padding: 0} }}
                // getContentAnchorEl={null}
            >
                <div>
                    <ClickAwayListener onClickAway={() => handleClose()}>
                        <div onClick={handleClose} className="flex flex-col items-stretch">
                            {children}
                        </div>
                    </ClickAwayListener>
                </div>

            </Menu>

        </div>

    );
}

export default DropDownMenuItem;

