import { createContext } from "react";



export interface SimpleNode {
    id: string,
    label: string
}

export interface LnGraph {
    nodes?: SimpleNode[];
    createdAt?: Date;
    getSimpleNode: (nodeId: string) => SimpleNode;
}

const SimpleNodeListContext = createContext<LnGraph>({
    nodes: undefined,
    createdAt: undefined,
    getSimpleNode: (nodeId: string) => undefined
 });

export default SimpleNodeListContext;