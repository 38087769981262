import * as React from 'react'
import Link from 'next/link'

import Logo from './Logo';
import MenuItem from './MenuItem';
import DropDownMenuItem from './DropDownMenuItem';
import AuthMenu from './AuthMenu';
import AuthGuard from '../auth/AuthGuard';
import ActionButton from '../general/ActionButton';

const Navigation: React.FC = ({ }) => {
    return (
        <div className="border-b">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center pt-6 pb-4">
                <div className="flex justify-center px-1">
                    <Link href="/">
                        <div className="cursor-pointer flex items-center">
                            <Logo></Logo> <span className="text-2xl pl-2">LnRouter</span>
                        </div>
                    </Link>
                </div>
                <div className="md:flex justify-end px-1 pt-2 md:pt-0">
                    <div className="flex flex-wrap justify-center">
                        <MenuItem path="/explore">
                            Explore
                        </MenuItem>
                        <DropDownMenuItem title="Graphs" path="/graph">
                            <MenuItem padding="big" path="/graph">Lightning network</MenuItem>
                            <MenuItem padding="big" path="/graph/zero-base-fee">#zeroBaseFee</MenuItem>
                            <MenuItem padding="big" path="/graph/betweenness-centrality">Betweenness centrality</MenuItem>
                        </DropDownMenuItem >
                        <DropDownMenuItem title="Tools" path="" useUnderline={false}>

                            <MenuItem padding="big" path="/scores/terminal" underlineMatch="partial">Terminal Score Debugger</MenuItem>
                            <MenuItem padding="big" path="/get-route" underlineMatch="partial">Shortest path</MenuItem>
                        </DropDownMenuItem >



                        <AuthGuard mustBe="authenticated">
                            <AuthMenu />
                        </AuthGuard>

                        <AuthGuard mustBe="unauthenticated">
                            <Link href="/auth/login">
                                <a>
                                    <ActionButton>
                                        <span className="capitalize">
                                            Sign in
                                        </span>
                                    </ActionButton>
                                </a>
                            </Link>
                        </AuthGuard>

                    </div>

                </div>
            </div>
        </div>


    );
}

export default Navigation;

