import * as React from 'react'
import SimpleNodeListContext, { SimpleNode } from './SimpleNodeListContext';



 type Props = {
  children: any
}


const SimpleNodeListProvider = ({ children }: Props) => {
  const [nodeList, setNodeList] = React.useState<SimpleNode[]>(undefined);
  const [createdAt, setCreatedAt] = React.useState<Date>(undefined);

  React.useEffect(() => {
    let isDestroyed = false;
    async function fetchData() {
        const res = await fetch('https://storage.googleapis.com/lnrouter-public/lnrouter.app/nodeList.json.gzip');
        const json = await res.json();
        if (!isDestroyed) {
          setNodeList(json.nodes);
          setCreatedAt(new Date(json.createdAt));
        }
    }
    if (!nodeList) {
      fetchData();
    }
    
    return () => {
      isDestroyed = true;
    }
  }, [])

  return (
    <SimpleNodeListContext.Provider value={{
      nodes: nodeList,
      createdAt: createdAt,
      getSimpleNode(nodeId) {
        const candidates = nodeList.filter(n => n.id === nodeId);
        if (candidates.length === 0) {
          return null;
        } else {
          return candidates[0];
        }
      }
      }}>
      {children}
    </SimpleNodeListContext.Provider>
  );
};

export default SimpleNodeListProvider;