import * as React from 'react'
import { Button, ButtonProps } from '@mui/material';
import Loader from "react-loader-spinner";


export interface Props extends ButtonProps {
    isLoading?: boolean
    className?: string
}

const ActionButton: React.FC<Props> = ({isLoading=false, className='', ...rest}: Props & ButtonProps) => {

    return (
        <Button 
        onClick={(e) => {
            if (isLoading) {
                return;
            }
            if (rest.onClick ) {
                rest.onClick(e);
            }
        }}
        className={className + " "} color="primary" variant="contained"
        {...rest} 
        disabled={rest.disabled || isLoading}
            disableElevation={true}>
            {isLoading &&
                <Loader color="#9ca3af" height="24px" type="ThreeDots" width="40px" />
            }

            {!isLoading &&
            rest.children
                
            }
        </Button>
    );
}

export default ActionButton;


