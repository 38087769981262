import * as React from 'react'
import FaceIcon from '@mui/icons-material/Face';
import DropDownMenuItem from './DropDownMenuItem';
import Loader from 'react-loader-spinner';
import MenuItem from './MenuItem';
import UserTypeGuard from '../auth/UserTypeGuard';
import { useLnRouterUser } from '../auth/useLnRouterUser';
import NodeLabel from '../general/NodeLabel';
import { useLnRouterAuth } from '../auth/useLnRouterAuth';
import { useRouter } from 'next/router';




type Props = {

}



const AuthMenu: React.FC<Props> = ({ }: Props) => {
  const router = useRouter();
  const auth = useLnRouterAuth();
  const user = useLnRouterUser();

  const paddingClasses = 'px-8 py-4';
  return (
    <DropDownMenuItem title={
      <FaceIcon className="text-gray-700" color="inherit" />
    } path="" >
      {user.data &&
        <React.Fragment>
          <div className={paddingClasses + " inline-block text-sm pb-4"}
            style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            <div className="font-medium text-lg tracking-wide">
            <NodeLabel nodeId={user.data.nodeId} />
              {user.data?.isAdmin &&
                <span className="text-sm text-viking-700 pl-1">Admin</span>
              }
              {user.data?.isBetaUser &&
                <span className="text-sm text-yellow-600 pl-1">Beta user</span>
              }
            </div>
            <div className="text-xs text-gray-400 break-all" style={{ maxWidth: '200px', fontSize: '8px' }}>
              {user.data?.nodeId}
            </div>
          </div>
            <MenuItem padding="big" path="/account/plan">
              My Plan
            </MenuItem>
          <UserTypeGuard mustBe="admin">
            <MenuItem padding="big" path="/admin/users">
              Admin
            </MenuItem>
          </UserTypeGuard>
          <div onClick={async () => {
            await auth.logout();   
            router.push('/');
          }} className={paddingClasses + 
            " inline-block text-sm text-gray-700 rounded font-medium cursor-pointer hover:bg-gray-100 "}>
            <span className={' normal-case '}>Logout</span>
          </div>
        </React.Fragment>

      }
      {!user.data &&
        <div className="px-12">
          <Loader width="50px" color="#27A3A4" type="ThreeDots"></Loader>
        </div>
      }


    </DropDownMenuItem>
  );
}

export default AuthMenu;

