import * as React from 'react'
import SimpleNodeListContext, { SimpleNode } from './SimpleNodeListContext';


type Props = {
  nodeId: string,
  removeEmojis?: boolean,
}

function removeEmoji(value: string): string {
  var ranges = [
    '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
    '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
    '\ud83d[\ude80-\udeff]'  // U+1F680 to U+1F6FF
  ];
  return value.replaceAll(new RegExp(ranges.join('|'), 'g'), '');
}

const NodeLabel = ({ nodeId, removeEmojis }: Props) => {
  const [node, setNode] = React.useState<SimpleNode>(undefined);
  const simpleNodes = React.useContext(SimpleNodeListContext);

  React.useEffect(() => {
    if (!simpleNodes.nodes || !nodeId) {
      setNode(undefined);
      return;
    }
    const node = JSON.parse(JSON.stringify(simpleNodes.getSimpleNode(nodeId)));
    if (removeEmojis) {
      node.label = removeEmoji(node.label);
    }
    
    setNode(node);
  }, [simpleNodes, nodeId]);

  return (
    <React.Fragment>
      {node && node.label}
    </React.Fragment>
  );
};

export default NodeLabel;