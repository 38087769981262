import Head from 'next/head';

import * as React from 'react'
import env from '../../lib/env';
import { GA_TRACKING_ID } from '../../lib/ga';


const defaults = {
    seoDescription: "Find Lightning Network insights for router node operators, graph visualizations and more to optimize your lightning node.",
    shareDescription: 'Lightning Network insights for router node operators and more.',

}

type Props = {
    pageTitle?: string,
    seoDescription?: string,
    shareDescription?: string,
};

const Header: React.FC<Props> = ({ pageTitle, seoDescription, shareDescription }: Props) => {
    let title = 'LnRouter - Lightning network insights';
    if (pageTitle) {
        title = pageTitle + ' - LnRouter';
    }
    const _seoDescription = seoDescription ? seoDescription : defaults.seoDescription;
    const _shareDescription = shareDescription ? shareDescription : defaults.shareDescription;
    return (
        <Head>
            <title>{title}</title>
            <meta name="description"
                content={_seoDescription} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={_shareDescription} />
            {/* <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg" /> */}
            <meta property="og:url" content="https://lnrouter.app" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={_shareDescription} />
            <meta name="twitter:image" content=" https://lnrouter.app/twitter_image.png" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content="@SeverinAlexB" />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="preconnect" href="https://fonts.gstatic.com"></link>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
            <link href="https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap" rel="stylesheet"></link>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

            {env.lnrouter_env === 'staging' &&
                <meta name="robots" content="noindex"></meta>
            }

            <script async src="https://www.googletagmanager.com/gtag/js?id=G-XEH3FTCN0E"></script>
            <script
                dangerouslySetInnerHTML={{
                    __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
    
              gtag('config', '${GA_TRACKING_ID}');
              `
                }}
            />
        </Head>
    );
}

export default Header;

